import { useEffect, useState } from "react"

import { useFetchProviderHomes } from "src/data/homes/queries/responseServiceQueries"
import { IFetchHomes } from "src/data/homes/types/homeQueryTypes"
import { THome, TMaybeHome } from "src/data/homes/types/homeTypes"
import { IProviderHomeResponse } from "src/data/homes/types/responseServiceTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { IMSelectResult, MSelect } from "src/ui/MSelect/MSelect"

export function HomesDropdown({
  value,
  onSelect,
  renderOption,
  getOptionDisabled,
  required,
  initialHomeId,
  fetchProviders,
}: {
  onSelect: (h: TMaybeHome) => void
  value: TMaybeHome
  renderOption?: (
    home: TMaybeHome,
    providerList?: IProviderHomeResponse
  ) => React.ReactNode
  getOptionDisabled?: (
    option: TMaybeHome,
    providerList?: IProviderHomeResponse
  ) => boolean
  required?: boolean
  initialHomeId?: string
  fetchProviders?: boolean
}) {
  const { t, langKeys } = useTranslate()
  const [search, setSearch] = useState("")
  const { orgId } = useOrganization()
  const fetchHomes = useFetchHomes({
    orgId,
    filters: { name: search },
    options: { select: homesDropdownQuerySelect },
  })

  const homeIds = fetchHomes.data?.homes.map((h) => h.home_id) || []

  const fetchProviderHomes = useFetchProviderHomes({
    orgId,
    homeIds: homeIds,
    options: {
      enabled: !!fetchProviders && homeIds?.length > 0,
    },
  })

  // If an initial id is provided, set that as our initial value as soon as the
  // data has been loaded.
  useEffect(() => {
    if (initialHomeId && !value?.name && fetchHomes.data?.homes) {
      const initialHome = fetchHomes.data?.homes.find(
        (h) => h.id === initialHomeId
      )
      onSelect(initialHome ?? null)
    }
  }, [fetchHomes.data?.homes, initialHomeId, onSelect, value])

  return (
    <MSelect
      options={fetchHomes.data?.homes ?? []}
      value={value ? addIdAndNameToHome(value) : null}
      onSearch={setSearch}
      onSelected={onSelect}
      loading={fetchHomes.isLoading || fetchProviderHomes.isInitialLoading}
      label={t(langKeys.home)}
      required={required}
      renderOption={
        !!renderOption
          ? (option) => renderOption(option, fetchProviderHomes.data)
          : undefined
      }
      getOptionDisabled={
        !!getOptionDisabled
          ? (option) => getOptionDisabled(option, fetchProviderHomes.data)
          : undefined
      }
    />
  )
}

function homesDropdownQuerySelect(q: IFetchHomes) {
  return { ...q, homes: addIdsToHomes(q.homes) }
}

/** Adds `id` field to home object */
function addIdAndNameToHome(h: THome): THome & IMSelectResult {
  return { ...h, id: h.home_id, name: h.name || h.home_id }
}

function addIdsToHomes(homes: THome[]) {
  return homes.map(addIdAndNameToHome)
}
